import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Cookie from 'js-cookie';

import * as actions from '../../store/actions/index';
import SideNavbar from '../../components/SideNavbar/SideNavbar';
import classes from './Home.module.css';
import ExerciseList from '../../components/ExerciseList/ExerciseList';
import ProjectList from '../../components/ProjectList/ProjectList';
import ComfirmationBox from '../../components/UI/ConfirmationBox/ConfirmationBox';
import AddProjectPopup from '../../components/ProjectList/AddProjectPopup/AddProjectPopup';
import Modal from '../../components/UI//Modal/Modal';
import { Redirect } from 'react-router-dom';
import addProjectIcon from '../../assets/addProject.svg'
import { Title } from '../../components/Title/Title';

import Spinner from '../../components/UI/Spinner/Spinner';
import Redirect404 from '../Redirect404/Redirect404';




class Home extends React.Component {
    state = {
        confirmationBox: false,
        confirmationId: null,
        addProjectPopup: false,
        projectName: '',
        openExercise: false,
        exerciseName: '',
        exerciseId: '',
        content: 'on_screen',
        data: [
            { 'activity': 'unplugged', 'concepts': 'random', 'timetaken': '15 mins', 'material': 'pdf' },
            { 'activity': 'unplugged', 'concepts': 'random', 'timetaken': '15 mins', 'material': 'pdf' },
            { 'activity': 'unplugged', 'concepts': 'random', 'timetaken': '15 mins', 'material': 'pdf' },
        ],
        lab: 'maze',
        edit: false,
        projectId: null
    }

    componentDidMount() {
        const userId = Cookie.get('userId');
        this.props.fetchExercises(this.props.match.params.lab, userId);
        this.props.fetchProjects(this.props.match.params.lab);
        this.props.fetchUnplugged(this.props.match.params.lab);

    }

    // componentWillUnmount() {
    //     window.location.reload();
    // }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.lab !== prevState.lab) {
            const userId = Cookie.get('userId');
            nextProps.fetchExercises(nextProps.match.params.lab, userId);
            nextProps.fetchProjects(nextProps.match.params.lab);
            nextProps.fetchUnplugged(nextProps.match.params.lab);
            return ({ lab: nextProps.match.params.lab });
        }
        return null;
    }

    toggleContent = (e, type) => {
        this.setState({
            content: type
        })
    }

    onLogoutHandler = () => {
        window.location = process.env.REACT_APP_AUTH_BASE + "logout";
    }

    showProjectPopup = (title, type, id = null) => {
        if (type === 'edit') {
            this.setState({
                projectName: title,
                edit: true,
                projectId: id
            })
        } else {
            this.setState({
                projectName: '',
                edit: false
            })
        }
        this.setState({
            addProjectPopup: true
        })
    }

    closeProjectPopup = () => {
        this.setState({
            addProjectPopup: false
        })
    }

    projectNameChangeHandler = (e) => {
        this.setState({
            projectName: e.target.value
        })
    }

    addProjectHandler = (e, name) => {
        if (this.state.projectName !== null) {
            if (!this.state.edit) {
                this.props.addProject(this.state.projectName, this.props.match.params.lab);
            } else {
                this.props.editProject(this.state.projectName, this.state.projectId);
            }
            this.closeProjectPopup();
        } else {
            //show error
        }
    }

    deleteProjectHandler = (e) => {
        this.props.deleteProject(this.state.confirmationId);
        this.setState({
            confirmationBox: false,
            confirmationId: null
        })
    }

    deleteConfirmationShow = (r, id) => {
        this.setState({
            confirmationBox: true,
            confirmationId: id
        })
    }

    componentDidUnMount() {
        window.location.reload()
    }


    deleteDeny = () => {
        this.setState({
            confirmationBox: false,
            confirmationId: null
        })
    }

    openExercise = (type, name, id) => {
        if (type === 0) {
            this.setState({ openExercise: true, exerciseName: name, exerciseId: id });
            localStorage.setItem("lab", this.props.match.params.lab);
        } else {
            this.props.history.push('/project/myproject/1')
        }
    }

    renderTableHeader = () => {
        let headerData = Object.keys(this.state.data[0]);
        let header = ["SR NO", ...headerData, 'Downloads'];
        return header.map((key, index) => {
            if (index === 0 || index === 5) {
                return <th key={index}>{key}</th>
            } else {
                return <th key={index}>{key.toUpperCase()}</th>
            }
        })
    }

    renderTableData = () => {
        return this.state.data.map((unplugged, index) => {
            const { activity, concepts, timetaken, material } = unplugged;
            return (
                <tr key={index}>
                    <td>{index}</td>
                    <td>{activity}</td>
                    <td>{concepts}</td>
                    <td>{timetaken}</td>
                    <td>{material}</td>
                    <td><span className={classes.letterStyle}>W</span><span className={classes.letterStyle}>A</span></td>
                </tr>
            )
        });
    }
    capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }


    render() {
        let ConfirmationBox = '';
        let addProjectPopup = '';
        let rightSideContent = '';



        if (this.state.openExercise) {
            return <Redirect push to={{
                pathname: '/exercise/' + this.state.exerciseName.replace(/ /g, "") + '/' + this.state.exerciseId + '/1',
                state: { title: this.state.exerciseName, lab: this.props.match.params.lab }
            }} />
        }
        if (this.state.addProjectPopup === true) {
            addProjectPopup = (
                <Modal show={this.state.addProjectPopup} modalClosed={this.closeProjectPopup}>
                    <AddProjectPopup projectName={this.state.projectName} Closed={this.closeProjectPopup} projectNameChangeHandler={this.projectNameChangeHandler} addProjectHandler={this.addProjectHandler} />
                </Modal>
            );
        }
        if (this.state.confirmationBox === true) {
            ConfirmationBox = (
                <Modal show={true}>
                    <ComfirmationBox confirm={this.deleteProjectHandler} deny={this.deleteDeny} text="Are you sure you want to delete it?" />
                </Modal>
            );
        }

        if (this.state.content === 'on_screen') {
            rightSideContent = (


                <Fragment>
                    {this.props.match.params.lab === 'game' ?
                        <Fragment>
                            <p className={classes.contentHeader}>Projects</p>
                            <div className={classes.projectSection}>
                                <div className={classes.addProject} onClick={this.showProjectPopup} >
                                    <img src={addProjectIcon} alt="add project" />
                                    <span>Add Project</span>
                                </div>
                                <ProjectList delete={this.deleteConfirmationShow} projects={this.props.projects} editPopUp={this.showProjectPopup} />
                            </div>
                        </Fragment>
                        : <Fragment>
                            <div className={classes.toggleContent}>
                                <p className={classes.contentHeader}>Exercises</p>
                                {/* <div className={classes.toggleContainer}>
                                    <button className={classes.btn} style={{ backgroundColor: '#fff', color: '#000' }} onClick={(e) => this.toggleContent(e, 'on_screen')}>On Screen</button>
                                    <button className={classes.btn} onClick={(e) => this.toggleContent(e, 'off_screen')}>Off Screen</button>
                                </div> */}
                            </div>
                            <div className={classes.exerciseSection}>
                                <ExerciseList exercises={this.props.exercises} clicked={this.openExercise} />
                            </div>
                            <p className={classes.contentHeader}>Projects</p>
                            <div className={classes.projectSection}>
                                <div className={classes.addProject} onClick={this.showProjectPopup} >
                                    <img src={addProjectIcon} alt="add project" />
                                    <span>Add Project</span>
                                </div>
                                <ProjectList delete={this.deleteConfirmationShow} projects={this.props.projects} editPopUp={this.showProjectPopup} />
                            </div>
                        </Fragment>
                    }
                </Fragment >
            )
        } else {
            rightSideContent = (
                <Fragment>
                    <div className={classes.toggleContent}>
                        <p className={classes.contentHeader}>Unplugged Activities</p>
                        {/* <div className={classes.toggleContainer}>
                            <button className={classes.btn} onClick={(e) => this.toggleContent(e, 'on_screen')} >On Screen</button>
                            <button className={classes.btn} onClick={(e) => this.toggleContent(e, 'off_screen')} style={{ backgroundColor: '#fff', color: '#000' }}>Off Screen</button>
                        </div> */}
                    </div>
                    <table className={classes.unpluggedTable}>
                        <thead>
                            <tr>{this.renderTableHeader()}</tr>
                        </thead>
                        <tbody>
                            {this.renderTableData()}
                        </tbody>
                    </table>
                    <div className={classes.letterDiv}>
                        <span className={classes.letterStyle}>W</span>
                        <span style={{ marginRight: '5px' }}>Download<br />Work sheet</span>
                        <span className={classes.letterStyle}>A</span>
                        <span>Download<br />Answer sheet</span>
                    </div>
                </Fragment>
            )
        }

        // if (this.props.history.location.pathname !== '/maze') {
        //     console.log(this.props.history.location.pathname, this.state.lab);
        //     return <Redirect404 />
        // }

        if (this.props.loading || this.props.projectLoading) {
            return <Spinner />
        }
        if (this.props.history.location.pathname === '/maze' || this.props.history.location.pathname === '/story' || this.props.history.location.pathname === '/game') {
            return (
                <div>
                    {/* { Navbar, Exercise List, Project List, Toggle, Unplugged List} */}
                    <Title title={"ITK - " + this.capitalizeFirstLetter(this.props.match.params.lab) + " Lab"} />
                    <div className={classes.container}>
                        <SideNavbar logout={this.onLogoutHandler} />
                        <div className={classes.rightSideContainer}>
                            {rightSideContent}
                            {ConfirmationBox}
                        </div>
                        {addProjectPopup}
                        {ConfirmationBox}
                    </div>
                </div>
            )
        } else {
            return <Redirect404 />
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.authLogout),
        fetchExercises: (lab, userId) => dispatch(actions.fetchExercises(lab, userId)),
        addProject: (name, lab) => dispatch(actions.addProject(name, lab)),
        editProject: (name, id) => dispatch(actions.editProject(name, id)),
        fetchProjects: (lab) => dispatch(actions.fetchProjects(lab)),
        deleteProject: (id) => dispatch(actions.deleteProject(id)),
        fetchUnplugged: (lab) => dispatch(actions.fetchUnplugged(lab))
    }
}

const mapStateToProps = state => {
    return {
        exercises: state.exercises.exercises,
        loading: state.exercises.loading,
        error: state.exercises.error,
        projects: state.projects.projects,
        projectsLoading: state.projects.loading,
        projectsError: state.projects.error
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);